import { buttonClasses, useTheme } from '@mui/material';
import {
  LoadingButton,
  loadingButtonClasses,
  type LoadingButtonProps,
} from '@mui/lab';
import type { LinkProps as RouterLinkProps } from '@remix-run/react';
import { COLORS } from '@tbd/life-tokens';
import { LinkBehavior } from '@tbd/lift-ui/mui';

export const textButtonClasses = Object.assign(
  {},
  loadingButtonClasses,
  buttonClasses
);

// These are types from MUI's `LoadingButtonProps` which *are not allowed* on the exported `TextButtonProps
type FilteredButtonProps = Omit<
  LoadingButtonProps,
  | 'variant'
  | 'color'
  | 'fullWidth'
  | 'children'
  | 'type'
  | 'disabled'
  | 'startIcon'
  | 'endIcon'
  | 'size'
>;

type SharedAdditionalProps = {
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'critical'
    | 'positive'
    | 'primary-inverted'
    | 'secondary-inverted'
    | 'tertiary-inverted';
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
};

type UnifiedSharedProps = FilteredButtonProps &
  // These are `react-router` props like `reloadDocument` and `replace`
  Omit<RouterLinkProps, 'to'> &
  SharedAdditionalProps;

type TextButtonProps = UnifiedSharedProps & {
  text: string;
  shape?: never;
  icon?: React.ReactNode;
  width?: 'auto' | 'manual';
};

type IconButtonProps = UnifiedSharedProps & {
  text: false;
  icon: React.ReactNode;
  shape: 'circle' | 'square';
};

type UnifiedTextButtonProps = TextButtonProps | IconButtonProps;

function getTextButtonLayoutStyles(
  size: UnifiedTextButtonProps['size'] = 'medium'
) {
  if (size === 'small') {
    return {
      padding: '6px 8px',
    };
  } else if (size === 'large') {
    return {
      padding: '14px 16px',
    };
  }
  return {
    padding: '8px 12px',
  };
}

function getIconButtonLayoutStyles(
  size: UnifiedTextButtonProps['size'] = 'medium'
) {
  if (size === 'small') {
    return {
      padding: '6px',
    };
  } else if (size === 'large') {
    return {
      padding: '14px',
    };
  }
  return {
    padding: '8px',
  };
}

function getVariantStyles(variant: UnifiedTextButtonProps['variant']) {
  let buttonVariant: LoadingButtonProps['variant'] = 'text';
  let buttonColor:
    | Extract<LoadingButtonProps['color'], 'primary' | 'error' | 'success'>
    | 'white' = 'primary';
  let buttonTextColor = '';
  let borderColor = '';

  if (variant === 'primary') {
    buttonVariant = 'contained';
  } else if (variant === 'secondary') {
    buttonVariant = 'outlined';
  } else if (variant === 'tertiary') {
    buttonVariant = 'text';
  } else if (variant === 'critical') {
    buttonVariant = 'contained';
    buttonColor = 'error';
  } else if (variant === 'positive') {
    buttonVariant = 'contained';
    buttonColor = 'success';
  } else if (variant === 'primary-inverted') {
    buttonVariant = 'contained';
    buttonColor = 'white';
    buttonTextColor = COLORS.primary_800;
  } else if (variant === 'secondary-inverted') {
    buttonVariant = 'outlined';
    buttonTextColor = 'white';
    borderColor = 'white';
  } else if (variant === 'tertiary-inverted') {
    buttonTextColor = 'white';
  }

  return { buttonVariant, buttonColor, buttonTextColor, borderColor };
}

function getManualButtonLayoutStyles(
  size: UnifiedTextButtonProps['size'] = 'medium'
) {
  if (size === 'small') {
    return {
      width: '160px',
      minWidth: '64px',
      padding: '8px 0px',
    };
  }
  if (size === 'large') {
    return {
      width: '240px',
      minWidth: '64px',
    };
  }
  return {
    width: '200px',
    minWidth: '64px',
    padding: '4px 0px',
  };
}

function mapSizeToTypographyVariant(
  size: UnifiedTextButtonProps['size'] = 'medium'
) {
  if (size === 'small') {
    return 'component-bold-s';
  }
  if (size === 'large') {
    return 'component-bold-l';
  }
  return 'component-bold-m';
}

function getButtonHeight(size: UnifiedTextButtonProps['size'] = 'medium') {
  if (size === 'small') {
    return '32px';
  }

  if (size === 'large') {
    return '52px';
  }

  // size === 'medium' or unknown
  return '40px';
}

export function TextButton(props: UnifiedTextButtonProps) {
  const theme = useTheme();
  const typographyVariant = mapSizeToTypographyVariant(props.size);
  const typographyStyles = theme.typography[typographyVariant];
  const ICON_SIZE = typographyStyles.lineHeight;

  const sizeLayoutStyles = props.text
    ? getTextButtonLayoutStyles(props.size)
    : getIconButtonLayoutStyles(props.size);
  const { buttonVariant, buttonColor, buttonTextColor, borderColor } =
    getVariantStyles(props.variant);
  const borderRadius = props.shape === 'circle' ? '50%' : '8px';
  const widthLayoutStyles =
    props.text && props.width === 'manual'
      ? getManualButtonLayoutStyles(props.size)
      : {};
  const overrides = props.text
    ? {}
    : {
        // use aspectRatio: 1 for either circle or square
        aspectRatio: 1,
        minWidth: 'unset',

        [`& .${buttonClasses.startIcon}`]: {
          // remove margin from icon to put it in the center
          margin: 'inherit',
          // keep icon consistent size
          height: ICON_SIZE,
          width: ICON_SIZE,
        },
        // keep icon consistent size
        [`& .${buttonClasses.startIcon}>*:nth-of-type(1) `]: {
          fontSize: ICON_SIZE,
        },
      };

  const { text, icon, sx, ...remainingProps } = props;

  return (
    <LoadingButton
      component={props.href ? LinkBehavior : undefined}
      // @ts-expect-error - unclear why this errors. color is only given  "primary" | "success" | "error" | undefined which are valid values
      color={buttonColor !== 'white' ? buttonColor : undefined}
      sx={{
        height: getButtonHeight(props.size),
        borderColor: borderColor,
        backgroundColor: buttonColor,
        borderRadius: borderRadius,
        ...sizeLayoutStyles,
        ...widthLayoutStyles,
        ...typographyStyles,
        color: buttonTextColor,
        ...overrides,
        ...sx,
      }}
      {...remainingProps}
      startIcon={icon}
      variant={buttonVariant}
    >
      {text || null}
    </LoadingButton>
  );
}
